﻿
window.onmessage = function (e) {
    var data = e.data;
    if (e.data.method == "updateContentBlockHtml") {

        if (data.html != "" && $("[content-block-id=" + data.id + "]").html() != data.html) {
            $("[content-block-id=" + data.id + "]").html(data.html);
            //var contentBlock = $("[content-block-id=" + data.id + "]");
            //contentBlock.wrapInner("<remove></remove>")
            //contentBlock.append(data.html);
            //contentBlock.find("remove").remove();
            editContentBlock(data.id);
        }

    }
    else if (e.data.method == "updateContentBlockZone")
    {
        $("[content-block-zone='" + data.zoneName + "']").html("");
        for (var i = 0; i < data.contentBlocks.filter(function (e) { return e.Zone == data.zoneName; }).length; i++) {
            $("[content-block-zone='" + data.zoneName + "']").append("<div content-block-id=" + data.contentBlocks[i].ID + ">" + data.contentBlocks[i].Html + "</div>");
        }
    }
    else if (e.data.method == "selectContentBlock")
    {
        $("[content-block-id=" + data.id + "]").addClass("selected");
        $("[content-block-id=" + data.id + "]")[0].scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
    }
    else if (e.data.method == "unSelectContentBlock") {
        $("[content-block-id=" + data.id + "]").removeClass("selected");
    }
    else if (e.data.method == "editContentBlock") {
        editContentBlock(data.id);
    }
    else if (e.data.method == "unEditContentBlocks") {
        $("[content-block-id] [content-editable-property]").each(function (index) {
            $(this).attr({ "contenteditable": "false" })
                .attr({ "placeholder": "הזן טקסט" })
                .off('input');
        });
        $("[content-block-id]").removeClass("selected");
    }
    else if (e.data.method == "runCommand")
    {
        eval('(' + decodeURI(e.data.command) + ')(' + e.data.params +');');
    }
};


function editContentBlock(id) {
    $("[content-block-id=" + id + "] [content-editable-property]").each(function (index) {
        $(this).attr({ "contenteditable": "true" })
            .attr({ "placeholder": "הזן טקסט" })
            .on('input', (e) => {
                window.parent.postMessage({
                    method: "updateViewModelProperty",
                    name: $(this).attr("content-editable-property"),
                    value: $(this).html()
                }, "*");
            });
    });
}

(function () {
    window.parent.postMessage({
        method: "pageLoaded",
        url: window.location.href
    }, "*");
})();
