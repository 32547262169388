﻿
// JS Dependencies
//import { Carousel } from 'bootstrap';
import jquery from 'jquery';
import 'jquery-ajax-unobtrusive';
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element'
import './core/editor'

// CSS Dependencies
/*import 'swiper/css/swiper.css'*/
import '../css/scss/main.scss';

Vue.use(vueCustomElement);

//window.Carousel = Carousel;

window.jquery = jquery;

import quantitySelector from './components/quantity-selector.vue'
Vue.component('quantity-selector', quantitySelector);